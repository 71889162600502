<template>
    <div>
        <v-container>
            <v-row class="mt-3">
                <v-btn color="success" @click="goToSale()">Create New Invoice<v-icon>mdi-plus-thick </v-icon></v-btn>
            </v-row>
            <v-row>
                <v-col md="12">
                <v-simple-table>
      <thead>
        <tr>
          <th>Invoice ID</th>
          <th class="text-center">Customer Name</th>
          <th class="text-center">Contact No.</th>
          <th class="text-center">Date</th>
          <th class="text-center">Time</th>
          <th class="text-center">Products</th>
          <th class="text-right">Amount</th>
        </tr>
      </thead>
      
      <tbody>
        
        <tr class="mouse-cursor"
          v-for="item in invoice"
          :key="item.invoice_id"
          @click="openInvoice(item.invoice_id)"
        >
          <td >{{ item.invoice_id }}</td>
            <td class="text-center">{{ item.invoice_customer.name }}</td>
            <td class="text-center">{{ item.invoice_customer.mobile }}</td>
            <td class="text-center">{{ item.invoice_date }}</td>
            <td class="text-center">{{ item.invoice_time }}</td>
            <td class="text-center">{{ item.invoice_items.length }}</td>
            <td class="text-right">৳ {{ getAmountWithComma(getTotalInvoice(item.invoice_items)) }}</td>
         
        </tr>
      </tbody>

  </v-simple-table>
  </v-col>
            </v-row>
        </v-container>
        
    </div>
</template>
<script>

  export default {
    name: 'InvoiceList',

    data: () => ({
        invoice: null
    }),

    methods:{
        getTotalInvoice(itemsArray){
            return itemsArray.reduce(function(total, i){
            return parseFloat(total) + (i.cart_qty * i.product_sale)
        },0)
        },
        
        openInvoice(i){
            this.$router.push({ path: '/invoice/'+i })
            
        },
        goToSale(){
            this.$router.push({ path: '/sales' })
        },
        getAmountWithComma(num){
         num = num.toFixed(2)
          num=num.toString();
          var afterPoint = '';
          if(num.indexOf('.') > 0)
            afterPoint = num.substring(num.indexOf('.'),num.length);
          num = Math.floor(num);
          num=num.toString();
          let lastThree = num.substring(num.length-3);
          let otherNumbers = num.substring(0,num.length-3);
          if(otherNumbers != '')
              lastThree = ',' + lastThree;
          let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
          
          return res;
        }

        // getAmountWithComma(num){
        //  num = num.toFixed(2)
        //   num=num.toString();
        //   var afterPoint = '';
        //   if(num.indexOf('.') > 0)
        //     afterPoint = num.substring(num.indexOf('.'),num.length);
        //   num = Math.floor(num);
        //   num=num.toString();
        //   let lastThree = num.substring(num.length-3);
        //   let otherNumbers = num.substring(0,num.length-3);
        //   if(otherNumbers != '')
        //       lastThree = ',' + lastThree;
        //   let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
          
        //   return res;
        // },
        // print(){
        //     window.print()
        // },
        // backToInvoice(){
        //     alert("Back To Invoice")
        // }



    },
    created(){

        this.invoice = JSON.parse(localStorage.getItem('invoice'));
        
    },
   
    computed:{

        
        // getTotal(){
        
        //   return this.invoice.invoice_items.reduce(function(total, i){
        //     return parseFloat(total) + (i.cart_qty * i.product_sale)
        // },0)
        
    // },
    //   getTotalWithComma(){
    //      return this.getAmountWithComma(this.getTotal)
    //   },


  }
}
</script>
<style scoped>
.heading-paragraph{
    margin: 0px;
    font-size: 14px;
}
</style>
