<template>
    <v-container fluid>
      <v-row class="mt-2 ml-1">
        <v-btn color="success" @click="goToInvoiceList()">Invoice List</v-btn>
      </v-row>
        <v-row>
            <v-col lg="4">

 


<v-simple-table v-if="this.loader">

      <thead class="t-head-color">
        <tr>
          <th>Product Name</th>
          <th>Quantity</th>
          <th>Purchase Rate</th>
          <th>Sales Rate</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
        </tr>
        <tr>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
        </tr>
        <tr>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
        </tr>
        <tr>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
        </tr>
        <tr>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
        </tr>        
      </tbody>
  </v-simple-table>





<v-simple-table v-if="!this.loader">

      <thead class="t-head-color">
        <tr>
          <th>Product Name</th>
          <th>Quantity</th>
          <th>Purchase Rate</th>
          <th>Sales Rate</th>
        </tr>
      </thead>
      <tbody>
        <tr  class="mouse-cursor"
          v-for="item in output"
          :key="item.name" @click="addToCart(item)"
        >
          <td>{{ item.product_name }}</td>
          <td>{{ item.product_quantity }} {{ item.product_unit }}</td>
          <td>{{ item.product_purchase }}</td>
          <td>{{ item.product_sale }}</td>
          
        </tr>
      </tbody>
  </v-simple-table>


            </v-col>
            <v-col lg="8">
             
    <v-row>
      <v-col md="8">
        <p class="pt-5">Select Customer</p>
        <select v-model="selectedCustomer" placeholder="dadad">
          <option selected value="">Please Select Customer</option>
          <option v-for="customer in customers" :key="customer.name" :value="customer">
            {{ customer.name }} - {{ customer.mobile }}
          </option>
        </select>
      </v-col>
      <v-col md="4">
        <v-card elevation="2" outlined color="#888" dark>
          <v-card-title>
            Total Amount:
          </v-card-title>
          <v-card-text>
            <h2 class="text-right">BDT {{ getGrandTotalWithComma }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>
    
    
    <v-simple-table>
      <thead>
        <tr>
          <th>Product Name</th>
          <th>Quantity</th>
          <th>Sales Rate</th>
          <th>Sub Total</th>
          <th>Action</th>
        </tr>
      </thead>
      
      <tbody>
        
        <tr
          v-for="item in cart"
          :key="item.product_name"
        >
          <td>{{ item.product_name }}</td>
<td>

        
        <v-btn :disabled="item.cart_qty<=1" @click="decreaseQuantity(cart.indexOf(item))" color="error" depressed x-small><v-icon x-small>mdi-minus-thick</v-icon></v-btn>

        <input class="cart_field_qty" v-model="item.cart_qty">
<v-btn @click="increaseQuantity(cart.indexOf(item))" color="success" depressed x-small><v-icon x-small>mdi-plus-thick</v-icon></v-btn>
    <span style="margin-left: 8px">
        {{ item.product_unit }}
    </span>
</td>
          <td><input class="cart_field_sale"  v-model="item.product_sale"></td>
          <td class="text-right">৳ {{ getSubTotalWithComma(parseFloat(item.cart_qty) * parseFloat(item.product_sale)) }}</td>
          <td><v-btn @click="removeFromCart(cart.indexOf(item))" color="error" depressed x-small><v-icon small>mdi-close-thick</v-icon></v-btn></td>
        </tr>
      </tbody>
      <tbody class="pt-8" v-if="this.cart.length!=0">
      <tr>
        <td><h4>Total Items: {{ this.cart.length }}</h4></td>
        <td></td>
        <td><h4 class="text-right">Total</h4></td>
        <td><h3 class="text-right">৳ {{ getTotalWithComma }}</h3></td>
        <td></td>
        </tr>
        <tr>
        <td></td>
        <td></td>
        <td><h4 class="text-right">Discount</h4></td>
        <td><input placeholder="0.00" style="float: right" class="cart_field_sale" v-model="discount"></td>
        <td></td>
        </tr>
        <tr>
        <td></td>
        <td></td>
        <td><h4 class="text-right">Grand Total</h4></td>
        <td><h3 class="text-right">৳ {{ getGrandTotalWithComma }}</h3></td>
        <td></td>
        </tr>
        </tbody>
  </v-simple-table>
  <h4 class="text-center pt-5" v-if="this.cart.length==0">Product Cart is Empty!</h4>


<v-dialog
      v-model="dialog"
      width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        class="mt-5"
          absolute
          color="success"
          right
          v-bind="attrs"
          v-on="on"
          :disabled="isSubmitButtonDisable()"
        >
          Create Invoice
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Invoice Information
        </v-card-title>

        <v-card-text>
         <p class="dialog-text">Customer: <span class="font-weight-bold">{{ selectedCustomer.name }} ({{ selectedCustomer.mobile }})</span></p>
         <p class="dialog-text">Address: <span class="font-weight-bold">{{ selectedCustomer.address }}</span></p>

    <v-simple-table>
      <thead>
        <tr>
          <th>Product Name</th>
          <th class="text-center">Quantity</th>
          <th class="text-right">Sales Rate</th>
          <th class="text-right">Subtotal</th>
        </tr>
      </thead>
      
      <tbody>
        
        <tr
          v-for="item in cart"
          :key="item.product_name"
        >
          <td >{{ item.product_name }}</td>
<td class="text-center">{{ item.cart_qty }} {{ item.product_unit }}</td>
          <td  class="text-right">৳ {{ getSubTotalWithComma(item.product_sale) }}</td>
          <td  class="text-right">৳ {{ getSubTotalWithComma(item.cart_qty * item.product_sale) }}</td>
        </tr>
      </tbody>
      <tbody class="pt-8" v-if="this.cart.length!=0">
      <tr>
        <td><h4>Total Items: {{ this.cart.length }}</h4></td>
        <td></td>
        <td><h4 class="text-right">Total</h4></td>
        <td><h3 class="text-right">৳ {{ getTotalWithComma }}</h3></td>
        </tr>
        <tr>
        <td></td>
        <td></td>
        <td><h4 class="text-right">Discount</h4></td>
        <td><h3 class="text-right" v-if="discount === null">৳ 0.00</h3><h3 class="text-right" v-else-if="discount === ''">৳ 0.00</h3><h3 class="text-right" v-else>৳ {{ getSubTotalWithComma(parseFloat(discount)) }}</h3></td>
        </tr>
        <tr>
        <td></td>
        <td></td>
        <td><h4 class="text-right">Grand Total</h4></td>
        <td><h3 class="text-right">৳ {{ getGrandTotalWithComma }}</h3></td>
        </tr>
        </tbody>
  </v-simple-table>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="createInvoice()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


            </v-col>












        </v-row>
    </v-container>
    
</template>
<script>
//import axios from 'axios'
  export default {
    name: 'Sales',

    data: () => ({
        search: null,
        output: null,
        cart: [],
        loader: true,
        discount: null,
        customers: null,
        selectedCustomer: "",
        dialog: false,
    }),
    methods:{
        addToCart(item){
           let temp = {
                "product_name": item.product_name,
                "product_purchase": item.product_purchase,
                "product_sale": item.product_sale,
                "product_quantity": item.product_quantity,
                "product_unit": item.product_unit,
                "cart_qty": 1
            }
            let currentProductCount = this.cart.filter(function(i){
              return i.product_name == temp.product_name
            }).length;

            if(currentProductCount==0){
              this.cart.push(temp)
            }else{
              let currentProductIndex = this.cart.findIndex(function(i){
                return i.product_name == temp.product_name
              })
              this.cart[currentProductIndex].cart_qty++;
            }
        },
        removeFromCart(itemIndex){
          this.cart.splice(itemIndex, 1)
        },
        increaseQuantity(itemIndex){
          this.cart[itemIndex].cart_qty++
        },
        decreaseQuantity(itemIndex){
          if(this.cart[itemIndex].cart_qty>1)
          this.cart[itemIndex].cart_qty--
        },
       
      getNumberWithComma(num){
        let lastThree, otherNumbers, res
       if(num % 1 === 0)
        {
          num=num.toString();
          lastThree = num.substring(num.length-3);
          otherNumbers = num.substring(0,num.length-3);
          if(otherNumbers != '')
              lastThree = ',' + lastThree;
          res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

          return res;
        }else{
          num = num.toFixed(2)
          num=num.toString();
          var afterPoint = '';
          if(num.indexOf('.') > 0)
            afterPoint = num.substring(num.indexOf('.'),num.length);
          num = Math.floor(num);
          num=num.toString();
          lastThree = num.substring(num.length-3);
          otherNumbers = num.substring(0,num.length-3);
          if(otherNumbers != '')
              lastThree = ',' + lastThree;
          res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
          
          return res;
        }
      
      },

      getSubTotalWithComma(num){
        num = parseFloat(num)
         num = num.toFixed(2)
          num=num.toString();
          var afterPoint = '';
          if(num.indexOf('.') > 0)
            afterPoint = num.substring(num.indexOf('.'),num.length);
          num = Math.floor(num);
          num=num.toString();
          let lastThree = num.substring(num.length-3);
          let otherNumbers = num.substring(0,num.length-3);
          if(otherNumbers != '')
              lastThree = ',' + lastThree;
          let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
          
          return res;
        },

        regen(){
          alert(this.search)
        },
        isSubmitButtonDisable(){
          if(this.selectedCustomer!='' && this.cart.length > 0){
            return false
          }else{
            return true
          }
        },
        nullDiscount(){
          this.discount = null
        },
        formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? '0'+hours : hours;
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
,
        createInvoice(){
           let a = JSON.parse(localStorage.getItem('invoice'))
           let dt = new Date()
           let date = ("0" + (parseInt(dt.getDate()))).slice(-2) +"/"+ ("0" + (parseInt(dt.getMonth()+1))).slice(-2)  +"/"+ dt.getFullYear()
           let time = this.formatAMPM(dt)

            let disc = 0.00
           if(this.discount != null && this.discount != ''){
             disc = this.discount
           }
           let invoice_data = {
             "invoice_id" : a.length+1,
             "invoice_date": date,
             "invoice_time" : time,
             "invoice_customer" : this.selectedCustomer,
             "invoice_items" : this.cart,
             "invoice_discount" : disc
             
             
             
             
           }
         a.push(invoice_data)
         localStorage.setItem('invoice', JSON.stringify(a));
         this.$router.push({ path: '/invoice/'+a.length })
        },
        goToInvoiceList(){
            this.$router.push({ path: '/invoice' })
        }
    },
    mounted(){
     
        this.output = JSON.parse(localStorage.getItem('stock'));
        this.customers = JSON.parse(localStorage.getItem('customer'))
        this.loader = false
//         var config = {
//     headers: {'Access-Control-Allow-Origin': 'https://us-central1-amar-manager-demo-d37fc.cloudfunctions.net/', 'Access-Control-Expose-Headers': '*', 'access-control-allow-origin': 'https://us-central1-amar-manager-demo-d37fc.cloudfunctions.net/addMessage'}
// };
        // this.output = axios.get('http://localhost:8080/'+'addMessage')
        //   .then((response) => {
        //     this.loader = false
        //     this.output = response.data
        //   })
        //   .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //   })
        //   .then(function () {
            
        //   });
    },
    computed:{
      getTotal(){
        if(this.cart.length>0){
          return this.cart.reduce(function(total, i){
            return parseFloat(total) + (i.cart_qty * i.product_sale)
        },0)
        }else{
          return 0
        }
        
        //return 10
      },
      getGrandTotal(){
        if(this.discount<this.getTotal){
          return this.getTotal-this.discount
        }
        else{
          this.nullDiscount()
          return this.getTotal
        }
      },
      getTotalWithComma(){
         return this.getSubTotalWithComma(this.getTotal)
      },
      getGrandTotalWithComma(){
        
          return this.getSubTotalWithComma(this.getGrandTotal)
        
      },

      intFloatTest(){
        
        if(this.getTotal % 1 === 0)
        {
          return "First"
        }else{
          return "Second"
        }
      }

     
    }
  }

</script>
<style lang="scss">
.cart_field_qty{
  border: 1px solid black;
  width: 70px;
  min-height: 35px;
  padding: 0px 5px;
  text-align: right;
  border-radius: 5px;
}
.cart_field_sale{
  border: 1px solid black;
  width: 100px;
  min-height: 35px;
  text-align: right;
  padding: 0px 5px;
}
.t-head-color{
  background-color: #2196F3;
  color: #fff;
}
.ms {
  position: absolute;
  top: 0;
  right: 10px;
}
.input-container {
  max-width: 208px;    /*adjust it*/
  margin: auto;
  position: relative;
  display: inline-block;
}

select {
        width: 100%;
        height: 30px;
        border: 1px solid #999;
        font-size: 18px;
        color: #000;
        background-color: #eee;
        padding: 0px 10px
      }
.dialog-text{
  margin: 5px 0px 0px 0px
}
</style>