<template>
  <v-container>
      <div class="block-center">
        <h1 class="pb-5">Welcome to আমার Manager Version 2.0</h1>
        <v-btn @click="startApp" x-large color="primary" dark :style="{margin: '20px 0px' ,left: '50%', transform:'translateX(-50%)'}">
            Get Started
            <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>



    
  </v-container>
</template>

<script>

  export default {
    name: 'Welcome',

    data: () => ({
        stock: [
            {
                "product_name": "Miniket Rice - Regular",
                "product_purchase": 61.50,
                "product_sale": 65.40,
                "product_quantity": 589.00,
                "product_unit": "KG"
            },
            {
                "product_name": "Lux Beauty Soap(Rose) - 125g",
                "product_purchase": 30.78,
                "product_sale": 35.00,
                "product_quantity": 19,
                "product_unit": "Pcs"
            },
            {
                "product_name": "Rupchanda Soyabean Oil - 1 Ltr",
                "product_purchase": 128.00,
                "product_sale": 140.00,
                "product_quantity": 12,
                "product_unit": "Pcs"
            },
            {
                "product_name": "Radhuni Termaric Powder - 100g",
                "product_purchase": 29,
                "product_sale": 35.00,
                "product_quantity": 8,
                "product_unit": "Pcs"
            },
            {
                "product_name": "Pran Chinigura Rice - 1KG",
                "product_purchase": 108.00,
                "product_sale": 120.00,
                "product_quantity": 19,
                "product_unit": "Pcs"
            }
            
        ],
        customer: [
            {
                "name": "Asif Salman Malik",
                "address": "Babu Para, Alamdanga, Chuadanga.",
                "mobile": "01770810050"
            },
            {
                "name": "Mahamudur Rahaman",
                "address": "Banasree, Dhaka",
                "mobile": "01915787135"
            },
            {
                "name": "Dolon Banerjee",
                "address": "Puraton Bus Stand, Alamdanga, Chuadanga.",
                "mobile": "01920696102"
            },
            {
                "name": "Aktaruzzaman Swopwon",
                "address": "Hatboalia, Alamdanga, Chuadanga.",
                "mobile": "01770810050"
            },
            {
                "name": "Aslam Hasib",
                "address": "Damurhuda, Chuadanga.",
                "mobile": "01711111111"
            },

        ]
    }),

    methods:{
        startApp(){
            localStorage.setItem('stock', JSON.stringify(this.stock));
            localStorage.setItem('customer', JSON.stringify(this.customer));
            if(localStorage.getItem('invoice')===null){
                localStorage.setItem('invoice', JSON.stringify([]));
            }
            
            this.$router.push({ path: '/sales' })
        }
    }
  }
</script>

<style lang="scss">

.block-center{
  position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
</style>