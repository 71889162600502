<template>
    <div>
        <div class="print-area">
            <v-container>
                <v-row>
                    <v-col md="12">
                        <h2 class="text-center mt-2">
                            A. R. Electric & Electronics
                        </h2>
                        <p class="text-center heading-paragraph">2F, Porimal Tower, N.S. Road, Kushtia - 7400.</p>
                        <p class="text-center heading-paragraph">Contact: 01711-236456, 01848-455987</p>
                        <p class="text-center mt-3">Invoice No# <span class="font-weight-bold">{{invoice.invoice_id}}</span></p>
                        <p class="heading-paragraph"><span class="font-weight-bold">Name:</span> <span class="font-weight-medium">{{ invoice.invoice_customer.name }} - {{ invoice.invoice_customer.mobile }}</span></p>
                        <p class="heading-paragraph"><span class="font-weight-bold">Address:</span> <span class="font-weight-medium">{{ invoice.invoice_customer.address }}</span></p>

                        <h4 class="text-center text-decoration-underline">Sales Invoice</h4>
                    </v-col>
                </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th>Product Name</th>
          <th class="text-center">Quantity</th>
          <th class="text-right">Sales Rate</th>
          <th class="text-right">Subtotal</th>
        </tr>
      </thead>
      
      <tbody>
        
        <tr
          v-for="item in invoice.invoice_items"
          :key="item.product_name"
        >
          <td >{{ item.product_name }}</td>
<td class="text-center">{{ item.cart_qty }} {{ item.product_unit }}</td>
          <td  class="text-right">৳ {{ getAmountWithComma(item.product_sale) }}</td>
          <td  class="text-right">৳ {{ getAmountWithComma(item.cart_qty * item.product_sale) }}</td>
        </tr>
      </tbody>
      <tbody class="pt-8">
      <tr>
        <td><h4>Total Items: {{ invoice.invoice_items.length }}</h4></td>
        <td></td>
        <td><h4 class="text-right">Total</h4></td>
        <td><h3 class="text-right">৳ {{ getTotalWithComma }}</h3></td>
        </tr>
        <tr>
        <td></td>
        <td></td>
        <td><h4 class="text-right">Discount</h4></td>
        <td><h3 class="text-right">৳ {{ getAmountWithComma(parseFloat(this.invoice.invoice_discount)) }}</h3></td>
        </tr>
        <tr>
        <td></td>
        <td></td>
        <td><h4 class="text-right">Grand Total</h4></td>
        <td><h3 class="text-right">৳ {{ getAmountWithComma(parseFloat(getTotal - this.invoice.invoice_discount)) }}</h3></td>
        </tr>
        </tbody>
  </v-simple-table>
<v-row class="mt-3 no-print">
    <v-btn color="primary" @click="backToInvoice()"><v-icon>mdi-arrow-left </v-icon>Back to Invoice List</v-btn>
    <v-spacer></v-spacer>
<v-btn color="success" x-large @click="print()"><v-icon>mdi-printer </v-icon>  Print Invoice</v-btn>
</v-row>
            </v-container>
        </div>
        
    </div>
</template>
<script>

  export default {
    name: 'Invoice',

    data: () => ({
        invoice: null
    }),

    methods:{
        getAmountWithComma(num){
         num = num.toFixed(2)
          num=num.toString();
          var afterPoint = '';
          if(num.indexOf('.') > 0)
            afterPoint = num.substring(num.indexOf('.'),num.length);
          num = Math.floor(num);
          num=num.toString();
          let lastThree = num.substring(num.length-3);
          let otherNumbers = num.substring(0,num.length-3);
          if(otherNumbers != '')
              lastThree = ',' + lastThree;
          let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
          
          return res;
        },
        print(){
            window.print()
        },
        backToInvoice(){
            this.$router.push({ path: '/invoice' })
        }
    },
    created(){
        this.invoice = JSON.parse(localStorage.getItem('invoice'))[this.$route.params.id-1];
        
    },
   
    computed:{
        getTotal(){
        
          return this.invoice.invoice_items.reduce(function(total, i){
            return parseFloat(total) + (i.cart_qty * i.product_sale)
        },0)
        
    },
      getTotalWithComma(){
         return this.getAmountWithComma(this.getTotal)
      },
  }
  }
</script>
<style scoped>
.heading-paragraph{
    margin: 0px;
    font-size: 14px;
}
</style>
